.react-date-range-input {
  .DateRangePickerInput {
    @apply flex flex-row items-center border border-neutral-200;

    input {
      @apply relative h-[42px] px-4 py-3 rounded-none text-sm font-normal bg-white border-none focus:border focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 focus:z-10;
      &::placeholder {
        @apply text-neutral-400;
      }
    }

    &_clearDates {
      right: -10px;
    }
  }
}
