.root {
  overflow: hidden;
}

#headlessui-portal-root {
  @apply text-neutral-900 dark:text-neutral-200 text-base;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.z-max {
  z-index: 999999999;
}

.app-will-change-transform {
  will-change: transform;
}

.app-will-change-top {
  will-change: top;
}

.app-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}

.app-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

.app-header-bg {
  @apply bg-white/70 dark:bg-neutral-900/60 backdrop-blur-2xl backdrop-filter;
}

.app-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

.app-PostCardCommentBtn,
.app-PostCardLikeAction,
.app-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.app-box-has-hover {
  @apply bg-white rounded-2xl border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.app-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.app-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-2xl;
}

.app-origin-100 {
  transform-origin: 100% 50% 0px;
}
.app-origin-50 {
  transform-origin: 50% 50% 0px;
}

.app-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-8;
}

.app-hero-field-padding--small {
  @apply py-3 px-4 md:py-3 md:px-7 xl:px-8;
}

.app-hero-field-focused {
  @apply rounded-full dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.ListingDetailPage {
  .app-hero-field-focused {
    @apply rounded-3xl shadow-2xl;
  }
  &.app-ListingExperiencesDetailPage {
    .ExperiencesDateSingleInput .app-hero-field-focused {
      @apply rounded-r-none;
    }
    .listingExperiencesDetailPage__GuestsInput .app-hero-field-focused {
      @apply rounded-l-none;
    }
  }

  .DateRangePickerInput_clearDates,
  .SingleDatePickerInput_clearDate {
    @apply rounded-full bg-neutral-200/90 dark:bg-white/10;
    svg {
      @apply w-2 fill-neutral-700 dark:fill-neutral-300;
    }
  }
}

.app-ListingStayDetailPage__guestsInput {
  .app-hero-field-focused {
    @apply rounded-t-none;
  }
}
.app-ListingStayDetailPage__stayDatesRangeInput {
  .app-hero-field-focused {
    @apply rounded-b-none;
  }
}

.app-hero-field-focused--2 {
  @apply rounded-full dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.app-flex-1 {
  flex: 1 0 0%;
}

.app-flex-1\.5 {
  flex: 1.5 0 0%;
}
.app-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.app-flex-2 {
  flex: 2 0 0%;
}

.app-flex-2-auto {
  flex: 2 0 auto;
}

.app-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:app-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:app-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:app-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:app-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:app-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.app-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
.app-icon-field-2 {
  @apply w-5 h-5 lg:w-8 lg:h-8;
}

button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

.app-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}


.rc-slider-track {
  @apply bg-primary-400;
}
.rc-slider-handle {
  @apply border-primary-300;
}


.app-PropertyCardH {
  .app-BtnLikeIcon--liked {
    @apply text-red-500 dark:text-red-500;
  }
}


.ncSectionLogos {
  img {
    @apply w-full;
    max-width: 200px;
  }
}

.modal-photos-gallery-slider {
  .glide__bullet--active {
    @apply block;
  }
}

.FooterNav--hide {
  @apply translate-y-full;
}

@keyframes myblur {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app-custom-shadow-1 {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.text-shadow-white {
  text-shadow: -0.5px -0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px 0.5px 0 #fff,
    0.5px 0.5px 0 #fff;
  letter-spacing: 0.5px;
}
