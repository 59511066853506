.async-react-select {
  @apply text-sm;

  .react-select__input {
    &:focus {
      box-shadow: none;
    }
  }

  .react-select__control {
    @apply border-neutral-200 hover:border-neutral-200 rounded-2xl;
    height: 44px;

    &--is-focused,
    &--menu-is-open {
      @apply hover:border-primary-300 border-primary-300 ring ring-primary-200 ring-opacity-50;

      .react-select__single-value {
        @apply text-neutral-500;

        
      }
    }

    &--is-disabled {
      @apply bg-neutral-100 opacity-70;
      .react-select__single-value {
        @apply text-neutral-900;  
      }
    }
  }

  &.rounded-none .react-select__control {
    border-radius: 0;
  }

  .react-select__placeholder {
    @apply text-black;
  }

  .react-select__dropdown-indicator {
    color: #6b7280;
  }
}
